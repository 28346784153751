$(() => {
  $("#clear-filter-span").on("click", () => {
    // inserted by forum_filters.html
    const { defaultSortOrder, defaultSortDirection } = window as unknown as {
      defaultSortOrder: string;
      defaultSortDirection: string;
    };
    $("select[name=listing_type]").val("");
    $("select[name=prefix_id]").val("0");
    $("#ctrl_started_by").val("");
    $("select[name=last_days]").val("-1");
    $("select[name=order]").val(defaultSortOrder);
    $("select[name=direction]").val(defaultSortDirection);
    $("#by_distance").attr("checked", "false");
    $("#filter-location").val("");
    $("#filter-lat").val("");
    $("#filter-lng").val("");
  });
});
